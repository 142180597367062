import React, { useState, useEffect } from 'react';
import { Box, Tabs, Tab, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const GuardRoster = () => {
    const [currentTab, setCurrentTab] = useState(0);
    const [rostersData, setRostersData] = useState([]);
    const [periods, setPeriods] = useState({});
    const userId = localStorage.getItem('userId');

    const handleChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const fetchRosters = async () => {
        try {
            const response = await fetch(`/roster/GetRosters?currentUserId=${userId.toString()}`);
            if (response.ok) {
                const fetchedRosters = await response.json();
                setRostersData(fetchedRosters);
            } else {
                console.error('Failed to fetch rosters.');
            }
        } catch (error) {
            console.error('Error during roster fetch:', error);
        }
    };

    const groupDataByPeriod = (data) => {
        return data.reduce((acc, item) => {
            if (!acc[item.period]) {
                acc[item.period] = [];
            }
            acc[item.period].push(item);
            return acc;
        }, {});
    };

    useEffect(() => {
        fetchRosters();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setPeriods(groupDataByPeriod(rostersData));
    }, [rostersData]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <Tabs
                value={currentTab}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="roster tabs"
            >
                {Object.keys(periods).length > 0 && Object.keys(periods).map((period, index) => (
                    <Tab
                        key={period}
                        label={`Roster ${period}`}
                        sx={{
                            '&:hover': {
                                backgroundColor: '#183656',
                                color: 'white'
                            },
                            width: 150,
                            borderRadius: 0.5,
                            textTransform: 'none',
                        }}
                    />
                ))}
            </Tabs>
            {Object.keys(periods).length > 0 ? Object.keys(periods).map((period, index) => (
                <TabPanel key={period} value={currentTab} index={index}>
                    <TableContainer component={Paper}>
                        <Table aria-label="roster table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Day</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Sites</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Date</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Time</Typography>
                                    </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.keys(periods).length > 0 ? periods[period].map((item) => (
                                    <TableRow key={item.id}>
                                        <TableCell>{item.dayName}</TableCell>
                                        <TableCell>{item.site}</TableCell>
                                        <TableCell>{item.formattedDate}</TableCell>
                                        <TableCell>{item.time}</TableCell>
                                    </TableRow>
                                )) : (
                                    <TableRow>
                                        <Typography variant="body1" align="center">
                                            No shifts in this period.
                                        </Typography>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>
            )) : (
                <Typography variant="body1" align="center">
                    You have not been rostered any shifts.
                </Typography>
            )}
        </Box>
    );
};

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component="div">{children}</Typography>
                </Box>
            )}
        </div>
    );
};

export default GuardRoster;