import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import { useAuth } from './Context/AuthContext';
import './custom.css';

const App = () => {
    const { isAuthenticated } = useAuth();

    return (
        <Routes>
            {AppRoutes.map((route, index) => {
                const { auth, ...rest } = route;

                // Check if the route requires authentication
                if (auth && !isAuthenticated) {
                    // Redirect to login if not authenticated
                    return (
                        <Route
                            key={index}
                            path={route.path}
                            element={<Navigate to="/login" />}
                        />
                    );
                }

                // Check if a custom layout is provided for the route
                const RenderedElement = route.layout || Layout;

                return (
                    <Route key={index} {...rest} element={<RenderedElement>{route.element}</RenderedElement>} />
                );
            })}
        </Routes>
    );
};

export default App;