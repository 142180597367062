import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAuth } from '../Context/AuthContext'; // Update the path accordingly
import logo from '../images/logo.jpg'
import Toast from './Toast';
import Spinner from './spinner';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://waltonsecurity.com.au/">
                Walton Security
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const defaultTheme = createTheme();

export default function SignIn() {

    const { login } = useAuth();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isTrue, setIsTrue] = useState(false);

    const handleLogin = async () => {
        try {
            setIsTrue(true);
            await login(username, password);
            setIsTrue(false);
            <Toast toastBody= "Successfully Logged in" />
            // Redirect to the desired page upon successful login
            // Change '/dashboard' to your desired path
        } catch (error) {
            <Toast toastBody= "An error occurred during login" />
            console.error('An error occurred during login:', error);
            // Handle other errors, e.g., show an error message
        }
    };


    const handleSubmit = (event) => {
        event.preventDefault(); // Prevents the default form submission behavior

        if (!username || !password) {
            setErrorMessage('Please enter your username and password.');
        } else {
            setErrorMessage('');
            handleLogin(); // Calls the handleLogin function
        }
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Box noValidate sx={{ width: [100, 150], height: [100, 150] }}>
                        <img src={logo} alt="Logo" style={{ width: '100%', height: '100%' }} />
                    </Box>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <form noValidate onSubmit={handleSubmit}>
                        <Box sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Username"
                                name="email"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                autoComplete="current-password"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Sign In {isTrue && <Spinner/>}
                            </Button>
                            {errorMessage && (
                                <Typography variant="body2" color="error">
                                    {errorMessage}
                                </Typography>
                            )}
                        </Box>
                    </form>
                </Box>
                <Copyright sx={{ mt: [8, 8], mb: 4 }} />
            </Container>
        </ThemeProvider>
    );
}