import React from 'react';
import { NavLink } from 'react-router-dom';
import {
    AppBar,
    Toolbar,
    Button,
    IconButton,
    Typography,
    Menu,
    MenuItem,
    Box,
    Hidden
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from '../Context/AuthContext';

const NavMenu = ({ sidebarCollapsed, setSidebarCollapsed }) => {
    const { logout } = useAuth();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const userId = localStorage.getItem('userId');
  
    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar position="fixed" color="primary" sx={{ backgroundColor: '#183656' }}>
            <Toolbar>
                {
                    userId === '1'  ? (
                        <>
                            <Typography variant="h6" sx={{ display: 'inline-block', flexGrow: 0, fontStyle: 'italic' }}>
                                <NavLink to="/dashboard" className="navbar-brand" style={{ color: 'white', textDecoration: 'none' }}>
                                    Spectrum
                                </NavLink>
                            </Typography>
                            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
                                <Hidden smDown>
                                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                        <Button
                                            color="inherit"
                                            component={NavLink}
                                            to="/dashboard"
                                            sx={{ '&:hover': { color: '#ccc' } }} // Change text color to light grey on hover
                                        >
                                            Home
                                        </Button>
                                        <Button
                                            color="inherit"
                                            component={NavLink}
                                            to="/templates"
                                            sx={{ '&:hover': { color: '#ccc' } }} // Change text color to light grey on hover
                                        >
                                            Compliance
                                        </Button>
                                        <Button
                                            color="inherit"
                                            component={NavLink}
                                            to="/schedule"
                                            sx={{ '&:hover': { color: '#ccc' } }} // Change text color to light grey on hover
                                        >
                                            Roster
                                        </Button>
                                        <Button
                                            color="inherit"
                                            component={NavLink}
                                            to="/timesheets"
                                            sx={{ '&:hover': { color: '#ccc' } }} // Change text color to light grey on hover
                                        >
                                            Timesheets
                                        </Button>
                                    </Box>
                                </Hidden>
                                <Hidden smUp>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        aria-label="menu"
                                        sx={{ width: 30, position: 'absolute', left: 120, top: 8 }}
                                        onClick={handleMenuClick}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                    <Menu
                                        id="mobile-menu"
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={handleMenuClose}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        PaperProps={{
                                            style: {
                                                marginTop: '50px',
                                            },
                                        }}
                                    >
                                        <MenuItem onClick={handleMenuClose} component={NavLink} to="/dashboard">Home</MenuItem>
                                        <MenuItem onClick={handleMenuClose} component={NavLink} to="/templates">Compliance</MenuItem>
                                        <MenuItem onClick={handleMenuClose} component={NavLink} to="/schedule">Roster</MenuItem>
                                    </Menu>
                                </Hidden>
                            </Box>
                        </>
                    ) : (
                        <>
                            <Typography variant="h6" sx={{ display: 'inline-block', flexGrow: 0, fontStyle: 'italic' }}>
                                <NavLink to="/guard-home" className="navbar-brand" style={{ color: 'white', textDecoration: 'none' }}>
                                    Spectrum
                                </NavLink>
                            </Typography>
                            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
                                <Hidden smDown>
                                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                        <Button
                                            color="inherit"
                                            component={NavLink}
                                            to="/guard-home"
                                            sx={{ '&:hover': { color: '#ccc' } }} // Change text color to light grey on hover
                                        >
                                            Home
                                        </Button>
                                        <Button
                                            color="inherit"
                                            component={NavLink}
                                            to="/guard-roster"
                                            sx={{ '&:hover': { color: '#ccc' } }} // Change text color to light grey on hover
                                        >
                                            Roster
                                        </Button>
                                    </Box>
                                </Hidden>
                                <Hidden smUp>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        aria-label="menu"
                                        sx={{ width: 30, position: 'absolute', left: 120, top: 8 }}
                                        onClick={handleMenuClick}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                    <Menu
                                        id="mobile-menu"
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={handleMenuClose}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        PaperProps={{
                                            style: {
                                                marginTop: '50px',
                                            },
                                        }}
                                    >
                                        <MenuItem onClick={handleMenuClose} component={NavLink} to="/guard-home">Home</MenuItem>
                                        <MenuItem onClick={handleMenuClose} component={NavLink} to="/guard-roster">Roster</MenuItem>
                                    </Menu>
                                </Hidden>
                            </Box>
                        </>
                    )
                }

                <IconButton
                    color="inherit"
                    onClick={logout}
                    sx={{ marginLeft: 2, width: 100, '&:hover': { color: '#ccc' } }}
                >
                    <LogoutIcon />
                    <Typography variant="button" sx={{ marginLeft: 1 }}>
                        Logout
                    </Typography>
                </IconButton>
            </Toolbar>
        </AppBar>
    );
};

export default NavMenu;