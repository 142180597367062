/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import CircularProgress from "@mui/material/CircularProgress";
import AddSiteModal from './AddSiteModal';
import AddTabModal from './AddTabModal';
import { convertDate } from '../shared/Utils';
import './RosterSystem.css';

const RosterSystem = () => {
    const [sites, setSites] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [timeSlots] = useState([
        '00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30',
        '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30',
        '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30',
        '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30',
        '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30',
        '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30',
        '24:00'
    ]);
    const [rosters, setRosters] = useState([]);
    const [activeRosterIndex, setActiveRosterIndex] = useState(0);
    const [selectedUserIds, setSelectedUserIds] = useState([]);
    const userId = localStorage.getItem('userId');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isTabModalOpen, setIsTabModalOpen] = useState(false);
    const [updatedShifts, setUpdatedShifts] = useState([]);
    const [removedShifts, setRemovedShifts] = useState([]);
    const [lastId, setLastId] = useState({ id: '', formattedDate: '' });

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await fetch(`/user/GetUsers/`);
                if (response.ok) {
                    const users = await response.json();
                    setAllUsers(users);
                } else {
                    console.error('Failed to fetch users.');
                }
            } catch (error) {
                console.error('Error during user fetch:', error);
            }
        };

        fetchUsers();
        if (allUsers.length > 0) {
            fetchRosters();
        }
        // Fetch rosters when the component mounts

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allUsers.length]);

    useEffect(() => {
        for (let i = 0; i < updatedShifts.length - 1; i++) {
            // If the current object and the next object have the same formattedDate and site
            if (updatedShifts[i].formattedDate === updatedShifts[i + 1].formattedDate &&
                updatedShifts[i].site === updatedShifts[i + 1].site) {
                // Discard the current object
                updatedShifts.splice(i, 1);
                // Decrement i to stay on the same index in the next iteration
                i--;
            }
        }
        for (let i = 0; i < removedShifts.length - 1; i++) {
            // If the current object and the next object have the same formattedDate and site
            if (removedShifts[i].formattedDate === removedShifts[i + 1].formattedDate &&
                removedShifts[i].site === removedShifts[i + 1].site) {
                // Discard the current object
                removedShifts.splice(i, 1);
                // Decrement i to stay on the same index in the next iteration
                i--;
            }
        }
        console.log("Updated Shifts", updatedShifts);
        console.log("Removed Shifts", removedShifts);
    }, [updatedShifts]);

    const formatDate = (date) => {
        const options = { month: '2-digit', day: '2-digit', year: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };

    // Function to get the day name from a date
    const getDayName = (dayIndex) => {
        const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        return daysOfWeek[dayIndex];
    };

    const generateRosters = (dateVal) => {
        // Validate the input and parse the date
        const startDate = dateVal ? new Date(dateVal) : new Date();

        // Check if the input is valid
        if (!isNaN(startDate.getTime())) {
            const days = Array.from({ length: 14 }, (_, index) => {
                const currentDate = new Date(startDate);
                currentDate.setDate(currentDate.getDate() + index);
                return {
                    dayName: getDayName(currentDate.getDay()),
                    formattedDate: formatDate(currentDate),
                };
            });

            const rosterDataForTab = sites.map((site, siteIndex) => ({
                siteId: site.id,
                site: site.name,
                days: days.map(day => ({
                    dayName: day.dayName,
                    formattedDate: day.formattedDate,
                    userId: '',
                    startTime: '', // Initialize startTime
                    endTime: '', // Initialize endTime
                })),
            }));

            setRosters((prevRosters) => [
                ...prevRosters,
                {
                    period: prevRosters.length + 1,
                    days,
                    rosterData: rosterDataForTab,
                },
            ]);
        } else {
            alert('Invalid date format. Using current date.');
            generateRosters(); // Retry with current date
        }
        setIsTabModalOpen(false);
    };

    const addSite = (newSiteVal) => {
        const maxSiteId = Math.max(...sites.map((site) => site.id), 0);
        const newSiteId = maxSiteId + 1;

        setSites((prevSites) => [...prevSites, { name: newSiteVal, id: newSiteId }]);

        setRosters((prevRosters) =>
            prevRosters.map((roster, rosterIndex) => {
                if (rosterIndex === activeRosterIndex) {
                    return {
                        ...roster,
                        rosterData: [
                            ...roster?.rosterData,
                            {
                                site: newSiteVal,
                                siteId: newSiteId,
                                days: roster.days.map((day) => ({ ...day, userId: '', startTime: '', endTime: '' })),
                            },
                        ],
                    };
                }
                return roster;
            })
        );

        setIsModalOpen(false);
    };

    // Function to handle assigning a user and shift time for a specific site on a given day
    const handleAssignShift = (siteId, formattedDate, dayName, attribute, value) => {
        setRosters((prevRosters) =>
            prevRosters.map((roster, index) => {
                if (index === activeRosterIndex) {
                    const newRosterData = roster.rosterData.map((row) => {
                        if (row.siteId === siteId) {
                            const newDays = row.days.map((day) => {
                                if (day.formattedDate === formattedDate && day.dayName === dayName) {
                                    const previousUserId = day.userId;
                                    console.log("Last ID: ", lastId);
                                    const updatedDay = { ...day ,[attribute]: value };
                                    const dayUpdated = { ...day, formattedDate: convertDate(day.formattedDate) ,[attribute]: value };
                                    //const allPropertiesFilled = Object.values(updatedDay).every(value => value !== '');
                                    if (attribute === 'userId') {
                                        if (previousUserId.length === 0) {
                                            // If userId was initially empty and is now added
                                            setUpdatedShifts(prevs => [...prevs, { ...dayUpdated, site: row.site, updated: false }]);
                                        } else if (previousUserId !== value) {
                                            // If userId is changed
                                            setUpdatedShifts(prevs => [...prevs, { ...dayUpdated, site: row.site, updated: true }]);
                                            setRemovedShifts(prevs => [...prevs, { ...day, site: row.site, updated: true }]);
                                        }
                                        setLastId({ id: value, formattedDate: day.formattedDate });
                                    } else if (attribute === 'startTime' || attribute === 'endTime') {
                                        // If startTime or endTime is changed
                                        if (lastId.id !== previousUserId) {
                                            // If userId has changed
                                            setUpdatedShifts(prevs => [...prevs, { ...dayUpdated, site: row.site, updated: true }]);
                                        } else if (lastId.id === previousUserId && lastId.formattedDate === day.formattedDate) {
                                            // If userId is same and formattedDate is same
                                            setUpdatedShifts(prevs => [...prevs, { ...dayUpdated, site: row.site, updated: false }]);
                                        } else if (lastId.id === previousUserId && lastId.formattedDate !== day.formattedDate) {
                                            // If userId is same but formattedDate is different
                                            setUpdatedShifts(prevs => [...prevs, { ...dayUpdated, site: row.site, updated: true }]);
                                        }
                                    }

                                    //If the userId is being changed
                                    if (attribute === 'userId' && previousUserId !== value) {
                                        setSelectedUserIds((prevIds) => {
                                            let updatedIds = [...prevIds];

                                            // Remove the previous userId if it exists in the list
                                            if (previousUserId) {
                                                updatedIds = updatedIds.filter(id => id !== previousUserId);
                                            }

                                            // Add the new userId if it's not already in the list
                                            if (value && !updatedIds.includes(value)) {
                                                updatedIds.push(value);
                                            }

                                            return updatedIds;
                                        });
                                    }
                                    return updatedDay;
                                }
                                return day;
                            });
                            return { ...row, days: newDays };
                        }
                        return row;
                    });

                    return { ...roster, rosterData: newRosterData };
                }
                return roster;
            })
        );
    };


    // Function to add a new roster tab
    const addRosterTab = (dateVal) => {
        const numTabs = rosters.length + 1;
        generateRosters(dateVal, numTabs);
        setActiveRosterIndex(numTabs - 1);
    };

    // Function to remove a site
    const removeSite = (site) => {
        const confirmRemove = window.confirm(`Are you sure you want to remove the site "${site}"?`);
        if (confirmRemove) {
            setSites((prevSites) => prevSites.filter((s) => s !== site));

            setRosters((prevRosters) =>
                prevRosters.map((roster, index) => {
                    if (index === activeRosterIndex) {
                        return {
                            ...roster,
                            rosterData: roster.rosterData.filter((row) => row.site !== site),
                        };
                    }
                    return roster;
                })
            );

            // Check if the removed site is in the active tab
            const removedSiteInActiveTab = rosters[activeRosterIndex]?.rosterData.some((row) => row.site === site);

            // If the removed site is in the active tab, stay on the current tab
            if (removedSiteInActiveTab) {
                setActiveRosterIndex(activeRosterIndex);
            } else {
                // If the removed site is the last one, switch to the previous tab
                if (activeRosterIndex === rosters.length - 1) {
                    setActiveRosterIndex((prevIndex) => Math.max(0, prevIndex - 1));
                }
            }
        }
    };

    const saveRosters = async () => {
        try {
            const formattedRosters = rosters.flatMap((roster) =>
                roster.rosterData.flatMap((rosterData) =>
                    rosterData.days.map((day) => ({
                        Period: roster.period,
                        SiteId: rosterData.siteId,
                        Site: rosterData.site,
                        DayName: day.dayName,
                        FormattedDate: day.formattedDate,
                        UserId: day.userId,
                        Time: `${day.startTime} - ${day.endTime}`,
                    }))
                )
            );

            // Get the selected user IDs
            //const selectedUserIds = rosters
            //    .flatMap(roster => roster.rosterData)
            //    .flatMap(rosterData => rosterData.days)
            //    .filter(day => day.userId !== '')
            //    .map(day => day.userId);


            console.log(formattedRosters, "Updated Roster");
            console.log("Selected User Ids", selectedUserIds);

            const response = await fetch('/roster/SaveAllRosters', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ rosterItems: formattedRosters, selectedUserIds: selectedUserIds, updatedShifts: updatedShifts, removedShifts: removedShifts }), // Send selected user IDs
            });

            if (response.ok) {
                const result = await response.json();
                if (result && result.Message) {
                    console.log(result.Message);
                    window.location.reload();
                } else {
                    console.error('Unexpected response format:', result);
                    window.location.reload();
                }
            } else {
                console.error('Failed to save rosters.');
            }
        } catch (error) {
            console.error('Error during roster save:', error);
        }
    };

    const transformApiResponse = (apiResponse, allUsers, timeSlots) => {
        const transformedRosters = [];

        // Group shifts by period
        const shiftsByPeriod = apiResponse.reduce((acc, shift) => {
            if (!acc[shift.period]) {
                acc[shift.period] = [];
            }
            acc[shift.period].push(shift);
            return acc;
        }, {});

        // Create rosters
        for (const period in shiftsByPeriod) {
            const shifts = shiftsByPeriod[period];

            // Group shifts by site within each period
            const shiftsBySite = shifts.reduce((acc, shift) => {
                if (!acc[shift.siteId]) {
                    acc[shift.siteId] = [];
                }
                acc[shift.siteId].push(shift);
                return acc;
            }, {});

            const rosterDataForTab = Object.entries(shiftsBySite).map(([siteId, siteShifts]) => ({
                siteId: parseInt(siteId),
                site: siteShifts[0].site, // Use site name from the first shift
                days: Array.from(new Set(siteShifts.map(shift => shift.formattedDate))).map(formattedDate => {
                    const correspondingShift = siteShifts.find(shift => shift.formattedDate === formattedDate);
                    const [startTime, endTime] = correspondingShift.time.split(' - '); // Split the time
                    return {
                        dayName: correspondingShift.dayName,
                        formattedDate: formattedDate,
                        userId: correspondingShift.userId,
                        startTime: startTime, // Assign startTime
                        endTime: endTime, // Assign endTime
                    };
                }),
            }));

            const days = Array.from(new Set(shifts.map((shift) => shift.formattedDate)))
                .sort((a, b) => new Date(a) - new Date(b))
                .map((formattedDate) => {
                    const correspondingShift = shifts.find((shift) => shift.formattedDate === formattedDate);
                    return {
                        dayName: correspondingShift.dayName,
                        formattedDate: formattedDate,
                    };
                });

            transformedRosters.push({
                period: parseInt(period),
                days: days,
                rosterData: rosterDataForTab,
            });
        }

        return transformedRosters;
    };


    const fetchRosters = async () => {
        console.log(allUsers, "hereeee users----")

        try {
            const response = await fetch(`/roster/GetRosters?currentUserId=${userId.toString()}`);
            if (response.ok) {
                const fetchedRosters = await response.json();
                const newAllusers = allUsers.map(user => user.userId)
                const transformedRosters = transformApiResponse(fetchedRosters, newAllusers.map(String), timeSlots);
                setRosters(transformedRosters);
                const latestPeriod = Math.max(...transformedRosters.map(roster => roster.period), 0);
                const latestPeriodIndex = transformedRosters.findIndex(roster => roster.period === latestPeriod);

                setActiveRosterIndex(latestPeriodIndex);

                const uniqueSiteIds = [...new Set(fetchedRosters.map(roster => roster.siteId))];
                setSites(prevSites => {
                    const updatedSites = [];

                    // Iterate through unique site IDs and update or add them to the state
                    uniqueSiteIds.forEach(siteId => {
                        const siteData = fetchedRosters.find(roster => roster.siteId === siteId);

                        if (siteData) {
                            const existingSite = prevSites.find(site => site.id === siteData.siteId);

                            if (existingSite) {
                                // Update the existing site's name
                                const updatedSite = { ...existingSite, name: siteData.site };
                                updatedSites.push(updatedSite);
                            } else {
                                // Add the new site to the state
                                updatedSites.push({ id: siteData.siteId, name: siteData.site });
                            }
                        }
                    });

                    return [...prevSites, ...updatedSites];
                });
            } else {
                console.error('Failed to fetch rosters.');
            }
        } catch (error) {
            console.error('Error during roster fetch:', error);
        }
    };

    const rosterLoading = rosters.length > 0 ? false : true;

    const isSiteAssigned = (siteId, dayName, formattedDate) => {
        const activeRoster = rosters[activeRosterIndex];
        if (activeRoster) {
            const siteData = activeRoster.rosterData.find(
                (site) => site.siteId === siteId
            );
            if (siteData) {
                const dayData = siteData.days.find(
                    (day) => day.dayName === dayName && day.formattedDate === formattedDate
                );

                return dayData?.userId === "24";
            }
        }
        return false;
    };

    return (
        <div className="roster-system">
            {userId === '1' && (
                <div className="controls">
                    <div className="add-site" onClick={() => setIsModalOpen(true)}>
                        Add Site +
                    </div>
                    <AddSiteModal
                        isOpen={isModalOpen}
                        onClose={() => {
                            setIsModalOpen(false);
                        }}
                        onSubmit={addSite}
                    />
                    <div className="add-tab" onClick={() => setIsTabModalOpen(true)}>
                        Add Tab +
                    </div>
                    <AddTabModal
                        isOpen={isTabModalOpen}
                        onClose={() => {
                            setIsTabModalOpen(false);
                        }}
                        onSubmit={addRosterTab}
                    />
                    <div className="save-rosters" onClick={saveRosters}>
                        Save Rosters
                    </div>
                </div>
            )}
            {rosters.length > 0 ? (
                <>
                    <div className="roster-tabs">
                        {rosters.map((roster, index) => (
                            <div
                                key={index}
                                className={`roster-tab ${index === activeRosterIndex ? 'active' : ''}`}
                                onClick={() => setActiveRosterIndex(index)}
                            >
                                {convertDate(roster?.days[0]?.formattedDate) + " - " + convertDate(roster?.days[roster?.days.length - 1].formattedDate)}
                            </div>
                        ))}
                    </div>
                    <div className="table-container">
                        <table className="roster-table">
                            <thead>
                                <tr>
                                    <th className="site-column">Site</th>
                                    {rosters[activeRosterIndex]?.days.map((day, index) => (
                                        <th key={index}>
                                            <div>
                                                <div>{day?.dayName}</div>
                                                <div>{convertDate(day?.formattedDate)}</div>
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {rosters[activeRosterIndex].rosterData.map((row, index) => (
                                    <tr key={row.site} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                                        <td>
                                            <div className="cell-container">
                                                <span>{row.site}</span>
                                                <button className="remove" onClick={() => removeSite(row.site)}>Remove</button>
                                            </div>
                                        </td>
                                        {row.days.map((cell, index) => (
                                            <td key={index} className={`${isSiteAssigned(row.siteId, cell.dayName, cell.formattedDate,) ? 'gray-cell' : ''
                                                }`}>
                                                <div className='cell-container'>
                                                    <select
                                                        value={cell?.userId}
                                                        className={cell?.userId ? 'odd-row' : 'empty-user'}
                                                        onChange={(e) =>
                                                            handleAssignShift(row.siteId, cell.formattedDate, cell.dayName, 'userId', e.target.value, index)
                                                        }
                                                    >
                                                        <option value="">
                                                            Select User
                                                        </option>
                                                        {allUsers.map((user) => (
                                                            <option key={user.userId} value={user.userId}>
                                                                {user.username}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <div className='parallel-time'>
                                                        <select
                                                            className={cell?.userId ? 'start-time odd-row' : 'start-time empty-user'}
                                                            value={cell?.startTime}
                                                            onChange={(e) =>
                                                                handleAssignShift(row.siteId, cell.formattedDate, cell.dayName, 'startTime', e.target.value)
                                                            }
                                                        >

                                                            {timeSlots.map((timeSlot) => (
                                                                <option key={timeSlot} value={timeSlot}>
                                                                    {timeSlot}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <select
                                                            className={cell?.userId ? 'end-time odd-row' : 'end-time empty-user'}
                                                            value={cell?.endTime}
                                                            onChange={(e) =>
                                                                handleAssignShift(row.siteId, cell.formattedDate, cell.dayName, 'endTime', e.target.value)
                                                            }
                                                        >

                                                            {timeSlots.map((timeSlot) => (
                                                                <option key={timeSlot} value={timeSlot}>
                                                                    {timeSlot}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            ) : (
                <>
                    {
                        !rosterLoading ? (
                            <p>No rosters available. Add a site to create the roster.</p>
                        ) : (
                            <div className='center-div'>
                                <CircularProgress />
                            </div>
                        )
                    }
                </>
            )}
        </div>
    );
};

export default RosterSystem;