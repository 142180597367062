import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import Home from "./components/Home";
import GuardHome from "./components/GuardHome";
import GuardRoster from "./components/GuardRoster";
import Login from "./components/Login";
import RosterSystem from "./components/Roster";
import SignupForm from "./components/SignupForm";
import Templates from "./components/Templates";
import SitesForGuards from "./components/SitesForGuards"


const NoLayout = ({ children }) => <>{children}</>;



const AppRoutes = [
    {
        index: true,
        element: <Login />,
        layout: NoLayout,
    },
    {
        path: '/dashboard',
        element: <Home />,
        auth: true,
    },
    {
        path: '/guard-home',
        element: <GuardHome />,
        auth: true,
    },
    {
        path: '/guard-roster',
        element: <GuardRoster />,
        auth: true,
    },
    {
        path: '/timesheets',
        element: <SitesForGuards />,
        auth: true,
    },
    {
        path: '/counter',
        element: <Counter />,
        auth: true,
    },
    {
        path: '/fetch-data',
        element: <FetchData />,
        auth: true,
    },
    {
        path: '/templates',
        element: <Templates />,
        auth: true,
    },

    {
        path: '/schedule',
        element: <RosterSystem />,
        auth: true,
    },

    {
        path: '/login',
        element: <Login />,
        layout: NoLayout,

    },

    {
        path: '/signup',
        element: <SignupForm />,
        layout: NoLayout,
    },

];

export default AppRoutes;
