import React, { useState, useEffect } from 'react';
import { showToast } from '../shared/Toaster';
import { Container, Box, Button, Typography, Grid, Card, CardContent, CardActions, IconButton, Modal, TextField } from '@mui/material';
import { Edit, Delete, Close as CloseIcon } from '@mui/icons-material';
import { Add as AddIcon } from '@mui/icons-material';
import CircularProgress from "@mui/material/CircularProgress";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Home.css';
import UserDetailsModal from './UserDetailsModal';

const Home = () => {
    const [users, setUsers] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
    const [editUserId, setEditUserId] = useState(null);
    const [newUsername, setNewUsername] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newBSB, setBSB] = useState('');
    const [newEmail, setEmail] = useState('');
    const [newLicenseExpiry, setLicenseExpiry] = useState('');
    const [newLicenseNo, setLicenseNo] = useState('');
    const [newPhoneNo, setPhoneNo] = useState('');
    const [newRate, setRate] = useState('');
    const [newAccount, setAccount] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const userId = localStorage.getItem('userId');

    const handleUserDetails = (user) => {
        setSelectedUser(user);
        setIsDetailsModalOpen(true);
    };

    // Fetch users
    const fetchUsers = async () => {
        try {
            const response = await fetch(`/user/GetUsers/`);
            if (response.ok) {
                const users = await response.json();
                setUsers(users);
            } else {
                console.error('Failed to fetch users.');
            }
        } catch (error) {
            console.error('Error during user fetch:', error);
        }
    };

    useEffect(() => {
        fetchUsers();
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 500);

        // Clean up the timer if the component unmounts before the timeout
        return () => clearTimeout(timer);
    }, []);

    const handleEditUser = async (event, userId) => {
        event.stopPropagation();
        setEditUserId(userId);
        const userToEdit = users.find(user => user.userId === userId);
        if (userToEdit) {
            console.log(userToEdit);
            setNewUsername(userToEdit.username);
            setEmail(userToEdit.email);
            setPhoneNo(userToEdit.phoneNo);
            setLicenseNo(userToEdit?.licenseNo);
            setLicenseExpiry(userToEdit?.licenseExpiry);
            setRate(userToEdit?.rate);
            setBSB(userToEdit?.bsb);
            setAccount(userToEdit?.account);
            setIsModalOpen(true);
        }
    };

    const handleDeleteUser = async (event, userId) => {
        event.stopPropagation();
        try {
            const response = await fetch(`/user/DeleteUser/${userId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                showToast('User deleted successfully.', 'success');
                fetchUsers();
            } else {
                console.error('User deletion failed.');
            }
        } catch (error) {
            console.error('Error during user deletion:', error);
        }
    };

    const handleCreateUser = async () => {
        try {
            const response = await fetch('/user/CreateUser', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username: newUsername, password: newPassword,
                    email: newEmail, phoneNo: newPhoneNo, licenseNo: newLicenseNo,
                    licenseExpiry: newLicenseExpiry, rate: newRate, bsb: newBSB,
                    account: newAccount
                }),
            });

            if (response.ok) {
                showToast('User created successfully.', 'success');
                fetchUsers();
                setIsModalOpen(false);
            } else {
                console.error('User creation failed.');
            }
        } catch (error) {
            console.error('Error during user creation:', error);
        }
    };

    const handleUpdateUser = async () => {
        try {
            const response = await fetch(`/user/EditUser/${editUserId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username: newUsername, password: newPassword,
                    email: newEmail, phoneNo: newPhoneNo, licenseNo: newLicenseNo,
                    licenseExpiry: newLicenseExpiry, rate: newRate, bsb: newBSB,
                    account: newAccount
                }),
            });

            if (response.ok) {
                showToast('User updated successfully.', 'success');
                fetchUsers();
                setIsModalOpen(false);
            } else {
                console.error('User update failed.');
            }
        } catch (error) {
            console.error('Error during user update:', error);
        }
    };

    return (
        <Container sx={{ mt: 4 }}>
            {/* <Typography variant="h3" align="center" gutterBottom>
                Guard Portal
            </Typography> */}

            {userId === '1' &&
                <Grid item xs={12} sm={6}>
                    <Box
                        mt={4}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            margin: '10px'
                        }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setEditUserId(null);
                                setNewUsername('');
                                setNewPassword('');
                                setIsModalOpen(true);
                            }}
                            startIcon={<AddIcon />}
                            sx={{
                                width: '30%',
                                backgroundColor: '#183656',
                                textTransform: 'none',
                                '@media (max-width: 600px)': {
                                    width: '50%',
                                    fontSize: '0.8rem'
                                },
                                '@media (max-width: 400px)': {
                                    width: '70%',
                                    fontSize: '0.7rem'
                                },
                                ':hover': {
                                    backgroundColor: 'grey'
                                }
                            }}
                        >
                            Create New User
                        </Button>
                    </Box>
                    <Typography variant="h4" gutterBottom>
                        Guards
                    </Typography>
                    <Grid container spacing={3}>
                        {!isLoading ? (users.map((user, index) => (
                            <Grid item xs={12} sm={4} key={index}>
                                <Card variant="outlined" onClick={() => handleUserDetails(user)}>
                                    <CardContent>
                                        <Typography variant="h6" component="div">
                                            <strong>{user.username}</strong>
                                        </Typography>
                                    </CardContent>
                                    <CardActions
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <IconButton
                                            onClick={(event) => handleEditUser(event, user.userId)}
                                            sx={{ p: '8px', width: 'auto' }}
                                        >
                                            <Edit />
                                        </IconButton>
                                        <IconButton
                                            onClick={(event) => handleDeleteUser(event, user.userId)}
                                            sx={{ p: '8px', width: 'auto' }}
                                        >
                                            <Delete />
                                        </IconButton>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))) : (
                            <Box sx={{ width: '100%', height: '100%', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <CircularProgress />
                            </Box>
                        )}
                    </Grid>
                    <Modal
                        open={isModalOpen}
                        onClose={() => {
                            setEditUserId(null);
                            setNewUsername('');
                            setNewPassword('');
                            setBSB('');
                            setEmail('');
                            setLicenseExpiry('');
                            setLicenseNo('');
                            setPhoneNo('');
                            setRate('');
                            setAccount('');
                            setIsModalOpen(false);
                        }}
                        aria-labelledby="modal-to-create-or-edit-a-user"
                    >
                        <Box sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '90%',
                            maxWidth: 650, // Increased width to fit two columns
                            maxHeight: '80vh', // Set a max height to the modal
                            overflowY: 'auto', // Enable vertical scrolling
                            border: '2px solid #e0e0e0',
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            borderRadius: 3,
                            p: 3,
                        }}>
                            <Box sx={{ position: 'relative', mb: 2 }}>
                                <IconButton
                                    onClick={() => {
                                        setEditUserId(null);
                                        setNewUsername('');
                                        setNewPassword('');
                                        setBSB('');
                                        setEmail('');
                                        setLicenseExpiry('');
                                        setLicenseNo('');
                                        setPhoneNo('');
                                        setRate('');
                                        setAccount('');
                                        setIsModalOpen(false);
                                    }}
                                    color="inherit"
                                    sx={{ position: 'absolute', top: 0, right: 0, width: 5, height: 5, '&:hover': { backgroundColor: 'transparent' } }}
                                >
                                    <CloseIcon />
                                </IconButton>
                                <Typography variant="h6" id="create-edit-user-modal" gutterBottom sx={{ textAlign: 'center', paddingTop: 4 }}>
                                    {editUserId ? 'Edit Guard' : 'Create New Guard'}
                                </Typography>
                            </Box>
                            <TextField
                                fullWidth
                                label="Username"
                                variant="outlined"
                                margin="normal"
                                value={newUsername}
                                onChange={(e) => setNewUsername(e.target.value)}
                            />
                            {!editUserId && (
                                <Box sx={{
                                    display: 'grid',
                                    gridTemplateColumns: '1fr 1fr',
                                    gap: 2,
                                }}>
                                    <TextField
                                        fullWidth
                                        type="password"
                                        label="Password"
                                        variant="outlined"
                                        margin="normal"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                    <TextField
                                        fullWidth
                                        label="BSB"
                                        variant="outlined"
                                        margin="normal"
                                        value={newBSB}
                                        onChange={(e) => setBSB(e.target.value)}
                                    />
                                    <TextField
                                        fullWidth
                                        type="email"
                                        label="Email"
                                        variant="outlined"
                                        margin="normal"
                                        value={newEmail}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <TextField
                                        fullWidth
                                        type="date"
                                        label="License Expiry"
                                        variant="outlined"
                                        margin="normal"
                                        InputLabelProps={{ shrink: true }}
                                        value={newLicenseExpiry}
                                        onChange={(e) => setLicenseExpiry(e.target.value)}
                                    />
                                    <TextField
                                        fullWidth
                                        label="License No"
                                        variant="outlined"
                                        margin="normal"
                                        value={newLicenseNo}
                                        onChange={(e) => setLicenseNo(e.target.value)}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Phone No"
                                        variant="outlined"
                                        margin="normal"
                                        value={newPhoneNo}
                                        onChange={(e) => setPhoneNo(e.target.value)}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Rate"
                                        variant="outlined"
                                        margin="normal"
                                        value={newRate}
                                        onChange={(e) => setRate(e.target.value)}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Account Number"
                                        variant="outlined"
                                        margin="normal"
                                        value={newAccount}
                                        onChange={(e) => setAccount(e.target.value)}
                                    />
                                </Box>
                            )}
                            {editUserId && (
                                <Box sx={{
                                    display: 'grid',
                                    gridTemplateColumns: '1fr 1fr',
                                    gap: 2,
                                }}>
                                    <TextField
                                        fullWidth
                                        label="BSB"
                                        variant="outlined"
                                        margin="normal"
                                        value={newBSB}
                                        onChange={(e) => setBSB(e.target.value)}
                                    />
                                    <TextField
                                        fullWidth
                                        type="email"
                                        label="Email"
                                        variant="outlined"
                                        margin="normal"
                                        value={newEmail}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <TextField
                                        fullWidth
                                        type="date"
                                        label="License Expiry"
                                        variant="outlined"
                                        margin="normal"
                                        InputLabelProps={{ shrink: true }}
                                        value={newLicenseExpiry}
                                        onChange={(e) => setLicenseExpiry(e.target.value)}
                                    />
                                    <TextField
                                        fullWidth
                                        label="License No"
                                        variant="outlined"
                                        margin="normal"
                                        value={newLicenseNo}
                                        onChange={(e) => setLicenseNo(e.target.value)}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Phone No"
                                        variant="outlined"
                                        margin="normal"
                                        value={newPhoneNo}
                                        onChange={(e) => setPhoneNo(e.target.value)}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Rate"
                                        variant="outlined"
                                        margin="normal"
                                        value={newRate}
                                        onChange={(e) => setRate(e.target.value)}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Account Number"
                                        variant="outlined"
                                        margin="normal"
                                        value={newAccount}
                                        onChange={(e) => setAccount(e.target.value)}
                                    />
                                </Box>
                            )}
                            <Box mt={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={editUserId ? handleUpdateUser : handleCreateUser}
                                    sx={{
                                        width: '100%',
                                        maxWidth: 200,
                                        backgroundColor: '#183656',
                                        textTransform: 'none',
                                        '@media (min-width:600px)': {
                                            width: '50%',
                                        },
                                    }}
                                >
                                    {editUserId ? 'Update' : 'Create'}
                                </Button>
                            </Box>
                        </Box>
                    </Modal>
                    <UserDetailsModal
                        isOpen={isDetailsModalOpen}
                        onClose={() => setIsDetailsModalOpen(false)}
                        user={selectedUser}
                    />
                </Grid>
            }
        </Container>
    );
};

export default Home;