import * as React from "react";

const Spinner = () => {
  return (
    <div
      className="spinner-border"
      role="status"
      style={{ marginLeft: "10px", width: "15px", height: "15px" }}
    ></div>
  );
};
export default Spinner;
