/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { CircularProgress } from "@mui/material";
import './RosterSystem.css';

const RosterSystem = () => {
    const [allUsers, setAllUsers] = useState([]);
    const [timeSlots] = useState([
        '00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30',
        '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30',
        '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30',
        '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30',
        '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30',
        '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30',
        '24:00',
    ]);
    const [rosters, setRosters] = useState([]);
    const [activeRosterIndex, setActiveRosterIndex] = useState(0);
    const userId = localStorage.getItem('userId');

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await fetch(`/user/GetUsers/`);
                if (response.ok) {
                    const users = await response.json();
                    setAllUsers(users);
                } else {
                    console.error('Failed to fetch users.');
                }
            } catch (error) {
                console.error('Error during user fetch:', error);
            }
        };

        fetchUsers();
    }, []);

    useEffect(() => {
        if (allUsers.length > 0) {
            fetchRosters();
        }
    }, [allUsers.length]);

    const transformApiResponse = (apiResponse, allUsers, timeSlots) => {
        const transformedRosters = [];

        const shiftsByPeriod = apiResponse.reduce((acc, shift) => {
            if (!acc[shift.period]) {
                acc[shift.period] = [];
            }
            acc[shift.period].push(shift);
            return acc;
        }, {});

        for (const period in shiftsByPeriod) {
            const shifts = shiftsByPeriod[period];
            const userTotalHours = allUsers.map(user => ({ userId: user.userId, username: user.username, totalHours: 0 }));
            const days = shifts.reduce((acc, shift) => {
                if (!acc.some(day => day.formattedDate === shift.formattedDate)) {
                    acc.push({
                        dayName: shift.dayName,
                        formattedDate: shift.formattedDate,
                    });
                }
                return acc;
            }, []);

            const rosterData = allUsers.map((user) => ({
                userId: user.userId,
                username: user.username,
                days: days.map(day => ({
                    dayName: day.dayName,
                    formattedDate: day.formattedDate,
                    sites: [],
                })),
            }));

            const latestShifts = {};

            for (const shift of shifts) {
                const shiftKey = `${shift.formattedDate}-${shift.userId}-${shift.site}`;
                if (!latestShifts[shiftKey] || new Date(shift.updatedAt) > new Date(latestShifts[shiftKey].updatedAt)) {
                    latestShifts[shiftKey] = shift;
                }
            }

            for (const shiftKey in latestShifts) {
                const shift = latestShifts[shiftKey];
                const user = rosterData.find(user => user.userId.toString() === shift.userId.toString());
                if (user) {
                    const day = user.days.find(day => day.formattedDate === shift.formattedDate);
                    if (day) {
                        const siteEntry = day.sites.find(site => site.name === shift.site);
                        if (siteEntry) {
                            siteEntry.times.push(shift.time);
                        } else {
                            day.sites.push({ name: shift.site, times: [shift.time] });
                        }

                        // Calculate total hours worked
                        const shiftDuration = calculateShiftDuration(shift.time);
                        const userTotal = userTotalHours.find(u => u.userId.toString() === shift.userId.toString());

                        if (userTotal) {
                            userTotal.totalHours += shiftDuration;
                        }
                    }
                }
            }

            transformedRosters.push({
                period: parseInt(period, 10),
                days,
                rosterData,
                totalHours: userTotalHours
            });
        }

        return transformedRosters;
    };

    const calculateShiftDuration = (timeString) => {
        const [start, end] = timeString.split('-');

        // Check if start and end times are the same (i.e., "00:00 - 00:00")
        if (start === end) {
            return 0;
        }

        const [startHour, startMinute] = start.split(':').map(Number);
        const [endHour, endMinute] = end.split(':').map(Number);

        let duration = (endHour * 60 + endMinute) - (startHour * 60 + startMinute);

        if (duration < 0) {
            duration += 24 * 60; // Adjust for overnight shifts
        }

        return duration / 60; // Convert to hours
    };

    const fetchRosters = async () => {
        try {
            const response = await fetch(`/roster/GetRosters?currentUserId=${userId}`);
            if (response.ok) {
                const rosters = await response.json();
                const transformedRosters = transformApiResponse(rosters, allUsers, timeSlots);
                setRosters(transformedRosters);
                setActiveRosterIndex(transformedRosters.length - 1); // Set the last tab as active
            } else {
                console.error('Failed to fetch rosters.');
            }
        } catch (error) {
            console.error('Error during roster fetch:', error);
        }
    };

    // Function to convert a single date from MM/DD/YYYY to DD/MM/YYYY
    const convertDate = (date) => {
        const [month, day, year] = date.split('/');
        return `${day}/${month}/${year}`;
    };

    return (
        <div>
            {rosters.length > 0 ? (
                <div>
                    <div className="roster-tabs">
                        {rosters.map((roster, index) => (
                            <div
                                key={index}
                                className={`roster-tab ${index === activeRosterIndex ? 'active' : ''}`}
                                onClick={() => setActiveRosterIndex(index)}
                            >
                                {convertDate(roster?.days[0]?.formattedDate) + " - " + convertDate(roster?.days[roster?.days.length - 1].formattedDate)}
                            </div>
                        ))}
                    </div>
                    <table className="roster-table">
                        <thead>
                            <tr>
                                <th>Guard Name</th>
                                {rosters[activeRosterIndex].days.map((day, index) => (
                                    <th key={index}>{day.dayName} {day.formattedDate}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {rosters[activeRosterIndex].rosterData.map((user, userIndex) => (
                                <tr key={userIndex}>
                                    <td>{user.username}</td>
                                    {user.days.map((day, dayIndex) => (
                                        <td key={dayIndex}>
                                            {day.sites.map((site, siteIndex) => (
                                                <div key={siteIndex}>
                                                    <strong>{siteIndex + 1}. {site.name}</strong>
                                                    <div>{site.times.join(', ')}</div>
                                                </div>
                                            ))}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <table className="total-hours-table mt-5 roster-table">
                        <thead>
                            <tr>
                                <th>Guard Name</th>
                                <th>Total Hours Worked</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rosters[activeRosterIndex].totalHours.map((user, index) => (
                                <tr key={index}>
                                    <td>{user.username}</td>
                                    <td>{user.totalHours >= 0 ? user.totalHours.toFixed(2) : "Select Time"}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div className="loading-container">
                    <CircularProgress />
                    <span>Loading data...</span>
                </div>
            )}
        </div>
    );
};

export default RosterSystem;