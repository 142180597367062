import React, { useState, useEffect } from 'react';
import { useAuth } from '../Context/AuthContext';
import { showToast } from '../shared/Toaster';
import CircularProgress from "@mui/material/CircularProgress";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Templates.css';
//import { Container } from 'reactstrap';

const Templates = () => {
    const { isAuthenticated } = useAuth();
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    // Function to fetch uploaded files
    const fetchFiles = async () => {
        try {
            const response = await fetch(`/templates/GetAllTemplateFiles`);
            if (response.ok) {
                const files = await response.json();
                setUploadedFiles(files);
            } else {
                console.error('Failed to fetch files.');
            }
        } catch (error) {
            console.error('Error during file fetch:', error);
        }
    };

    useEffect(() => {
        fetchFiles();
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 500);

        // Clean up the timer if the component unmounts before the timeout
        return () => clearTimeout(timer);
        // setIsLoading(false);
    }, []);

    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    const handleFileUpload = async () => {
        const userId = localStorage.getItem('userId');
        if (selectedFile) {
            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('userId', userId);

            try {
                const response = await fetch('/templates/UploadTemplateFile', {
                    method: 'POST',
                    body: formData,
                });

                if (response.ok) {
                    const result = await response.json();
                    showToast(`File "${result.fileName}" uploaded successfully.`, 'success');
                    // After successful upload, refresh the list of files
                    fetchFiles();
                } else {
                    console.error('File upload failed.');
                }
            } catch (error) {
                console.error('Error during file upload:', error);
            }
        } else {
            showToast('Please select a file for upload.', 'error');
        }
    };

    const handleFileDownload = async (fileId, fileName) => {
        try {
            const response = await fetch(`/templates/DownloadFile/${fileId}`, {
                headers: {
                    'Content-Type': 'application/pdf',
                },
            });

            if (response.ok) {
                // Convert the response to a blob
                const blob = await response.blob();

                // Create a blob URL for the blob
                const url = URL.createObjectURL(blob);

                // Create an anchor element
                const a = document.createElement('a');

                // Set the anchor's href attribute to the blob URL
                a.href = url;

                // Set the download attribute to the file name
                a.download = fileName;

                // Append the anchor to the document body
                document.body.appendChild(a);

                // Click the anchor to trigger the download
                a.click();

                // Remove the anchor from the document body
                document.body.removeChild(a);

                // Revoke the blob URL
                URL.revokeObjectURL(url);
            } else {
                console.error('File download failed.');
            }
        } catch (error) {
            console.error('Error during file download:', error);
        }
    };

    function formatDate(dateString) {
        // Parse the date string into a Date object
        const date = new Date(dateString);

        // Format the date to display day, month name, and year
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        return date.toLocaleDateString(undefined, options);
    }

    return (
        <div>
            {isAuthenticated ? (
                <>
                    <div className="file-actions">
                        <input type="file" onChange={handleFileSelect} />
                        <button className="upload-button" onClick={handleFileUpload}>Upload</button>
                    </div>
                    <div className="pdf-table">
                        <h4>Uploaded PDFs</h4>
                        {!isLoading ? (
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Date Uploaded</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {uploadedFiles.map((row, index) => (
                                        <tr key={index}>
                                            <td>{row.fileName}</td>
                                            <td>{formatDate(row.uploadDate)}</td>
                                            <td>
                                                <button className="download-button" onClick={() => handleFileDownload(row.id, row.fileName)}>
                                                    Download
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )
                            : (
                                <div className='center-div'>
                                    <CircularProgress />
                                </div>
                            )}
                    </div>
                </>
            ) : (
                <p>Please log in to access the admin dashboard.</p>
            )}
        </div>

    );
};

export default Templates;