import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
//import { showToast } from '../../src/shared/Toaster';
import { Toaster } from '../shared/Toaster';
import Toast from '../components/Toast';
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('authToken'));
    const [token, setToken] = useState(localStorage.getItem('authToken'));
    const navigate = useNavigate();

    useEffect(() => {
        const storedToken = localStorage.getItem('authToken');
        if (storedToken) {
            setToken(storedToken);
            setIsAuthenticated(true);
        }
    }, []);

    const login = async (username, password) => {
        try {
            const response = await fetch('/user/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username: username,
                    password: password,
                }),
            });

            if (response.ok) {
                const result = await response.json();
                console.log(result, "result------")
                console.log("Login successful. Token:", result.token);
                <Toast toastBody="Successfully Logged in" />
                // Store the token in state and localStorage
                //showToast("Successfully Logged in", "success");
                setToken(result.token);
                localStorage.setItem('authToken', result.token);
                localStorage.setItem('userId', result.userId);
                setIsAuthenticated(true);
                if(result.userId === 1 || result.userId === 2) {
                    navigate('/dashboard');
                } else {
                    navigate('/guard-roster');
                }
            } else {
                const errorData = await response.json();
                //showToast(errorData.Message, "error");
                <Toast toastBody={errorData.message} />
                console.error("Login failed:", errorData.message);
                navigate('/login');
            }
        } catch (error) {
            <Toaster toastBody="An error occurred during login" />
            console.error("An error occurred during login:", error);
            //showToast("An unexpected error occurred.", "error");
        }
    };

    const logout = () => {
        // Clear the token from state and localStorage
        setToken(null);
        localStorage.removeItem('authToken');
        localStorage.removeItem('userId');
        setIsAuthenticated(false);
        navigate('/login');
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, token, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
