import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import { Close as CloseIcon } from '@mui/icons-material';
import Button from '@mui/material/Button';

const AddSiteModal = ({ isOpen, onClose, onSubmit }) => {
    const [siteName, setSiteName] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        onSubmit(siteName);
        setSiteName('');
    };

    return (
        <Modal open={isOpen} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '80%',
                maxWidth: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                borderRadius: 3,
                p: 3,
            }}>
                <IconButton
                    onClick={onClose}
                    color="inherit"
                    sx={{ position: 'absolute', top: 5, right: 10, width: 5, height: 5, '&:hover': { backgroundColor: 'transparent' } }}
                >
                    <CloseIcon />
                </IconButton>
                <TextField
                    fullWidth
                    label="Site Name"
                    variant="outlined"
                    margin="normal"
                    value={siteName}
                    onChange={(e) => setSiteName(e.target.value)}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    sx={{
                        width: '100%',
                        maxWidth: 200,
                        backgroundColor: '#183656',
                        textTransform: 'none',
                        '@media (min-width:600px)': {
                            width: '50%',
                        },
                    }}
                >
                    Add Site
                </Button>
            </Box>
        </Modal>
    );
};

export default AddSiteModal;
