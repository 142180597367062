import React, { Component } from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Layout.css';

export class Layout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sidebarCollapsed: false,
        };
    }

    toggleSidebar = () => {
        this.setState((prevState) => ({
            sidebarCollapsed: !prevState.sidebarCollapsed,
        }));
    };

    render() {
        return (
            <div className={`layout-container ${this.state.sidebarCollapsed ? 'sidebar-collapsed' : ''}`}>
                <NavMenu setSidebarCollapsed={this.toggleSidebar} sidebarCollapsed={this.state.sidebarCollapsed} />
                <div id="content">
                    <Container fluid>
                        {this.props.children}
                    </Container>
                </div>
            </div>
        );
    }
}

export default Layout;