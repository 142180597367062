// SignupForm.js

import React, { useState } from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import "./signupForm.css"; // Import the styles

const SignupForm = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [phone, setPhone] = useState("");
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");

    const handleSignup = () => {
        // Add your signup logic here
        console.log("Signing up with:", email, password, phone, name, surname);
    };

    return (
        <div className="signup-container">
            <form className="signup-form">
                <h2>Sign Up</h2>
                <label>
                    Email:
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </label>
                <label>
                    Password:
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </label>
                <label>
                    Phone Number:
                    <input
                        type="tel"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                </label>
                <label>
                    Name:
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </label>
                <label>
                    Surname:
                    <input
                        type="text"
                        value={surname}
                        onChange={(e) => setSurname(e.target.value)}
                    />
                </label>
                <button type="button" onClick={handleSignup}>
                    Sign Up
                </button>
                {/* Signup link */}
                <p>
                    Already have an account? <Link to="/login">Login</Link>
                </p>
            </form>
        </div>
    );
};

export default SignupForm;

