import * as React from "react";
const Toast = ({ toastBody }) => {
  console.log(toastBody, "toastBody");
  return (
    <div className="toast" role="alert" ariaLive="assertLive" ariaAtomic="true">
      <div className="toast-header">
        <button
          type="button"
          className="ml-2 mb-1 close"
          dataDismiss="toast"
          ariaLabel="Close"
        >
          <span ariaHidden="true">&times;</span>
        </button>
      </div>
      <div className="toast-body">{toastBody}</div>
    </div>
  );
};

export default Toast;
