import React, { useState, useEffect } from 'react';
import { Container, Typography, Grid, List, ListItem, ListItemText, Divider, Box } from '@mui/material';
import CircularProgress from "@mui/material/CircularProgress";

const GuardHome = () => {
    const [users, setUsers] = useState([]);
    const [currentUser, setCurrentUser] = useState(null);
    const [uploadedFiles, setUploadedFiles] = useState([]);

    const fetchUsers = async () => {
        try {
            const response = await fetch(`/user/GetUsers/`);
            if (response.ok) {
                const users = await response.json();
                setUsers(users);
            } else {
                console.error('Failed to fetch users.');
            }
        } catch (error) {
            console.error('Error during user fetch:', error);
        }
    };

    const fetchFiles = async () => {
        try {
            const response = await fetch(`/templates/GetAllTemplateFiles`);
            if (response.ok) {
                const files = await response.json();
                setUploadedFiles(files);
            } else {
                console.error('Failed to fetch files.');
            }
        } catch (error) {
            console.error('Error during file fetch:', error);
        }
    };

    // Function to get userId from localStorage
    const getUserIdFromLocalStorage = () => {
        const userId = localStorage.getItem('userId');
        return userId;
    };

    // Function to filter the user based on userId
    const filterUserByUserId = (userId) => {
        const user = users.find((user) => user.userId === parseInt(userId));
        return user;
    };

    useEffect(() => {
        fetchUsers();
        fetchFiles();
    }, []);

    useEffect(() => {
        const userId = getUserIdFromLocalStorage();

        // If userId exists, filter the user and set as currentUser
        if (userId && users.length > 0) {
            console.log(userId);
            const filteredUser = filterUserByUserId(userId);
            setCurrentUser(filteredUser);
        }
    }, [users]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Container sx={{ mt: 4 }}>
            <Typography variant="h3" align="center" gutterBottom>
                Guard Portal
            </Typography>
            {/* Username Section */}
            <Grid container>
                {currentUser ? (
                    <>
                        <Grid item xs={5} sx={{ paddingLeft: [8, 20, 30] }}>
                            <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>
                                {currentUser.username}
                            </Typography>
                            {currentUser.phoneNo && (
                                <>
                                    <Typography variant="h8" gutterBottom>
                                        Phone No: {currentUser.phoneNo}
                                    </Typography>
                                    <br />
                                </>
                            )}
                            {currentUser.email && (
                                <>
                                    <Typography variant="h8" gutterBottom>
                                        Email: {currentUser.email}
                                    </Typography>
                                    <br />
                                </>
                            )}
                            {currentUser.licenseNo && (
                                <>
                                    <Typography variant="h8" gutterBottom>
                                        License No: {currentUser.licenseNo}
                                    </Typography>
                                    <br />
                                </>
                            )}
                            {currentUser.licenseExpiry && (
                                <>
                                    <Typography variant="h8" gutterBottom>
                                        License Expiry: {currentUser.licenseExpiry}
                                    </Typography>
                                    <br />
                                </>
                            )}
                            {currentUser.rate && (
                                <>
                                    <Typography variant="h8" gutterBottom>
                                        Rate: {currentUser.rate}
                                    </Typography>
                                    <br />
                                </>
                            )}
                            {currentUser.bsb && (
                                <>
                                    <Typography variant="h8" gutterBottom>
                                        BSB: {currentUser.bsb}
                                    </Typography>
                                    <br />
                                </>
                            )}
                            {currentUser.account && (
                                <Typography variant="h8" gutterBottom>
                                    Account No: {currentUser.account}
                                </Typography>
                            )}
                        </Grid>
                        {/* Vertical Divider */}
                        <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Divider orientation="vertical" sx={{ width: 2, backgroundColor: 'black', height: '100%' }} />
                        </Grid>

                        {/* Files Section */}
                        <Grid item xs={5} sx={{ paddingLeft: [2, 5, 10] }}>
                            <Typography variant="h8" gutterBottom>
                                Compliance Documents
                            </Typography>
                            <List>
                                {uploadedFiles && uploadedFiles.length > 0 ? (
                                    uploadedFiles.map((file, index) => (
                                        <ListItem key={index}>
                                            <ListItemText secondary={file.fileName.split('.').slice(0, -1).join('.')} />
                                        </ListItem>
                                    ))
                                ) : (
                                    <ListItem>
                                        <ListItemText primary="No files uploaded" />
                                    </ListItem>
                                )}
                            </List>
                        </Grid>
                    </>
                ) : (
                    <Box sx={{ width: '100%', height: '100%', display: "flex", justifyContent: "center" }}>
                        <CircularProgress />
                    </Box>
                )}
            </Grid>
        </Container >
    );
};

export default GuardHome;