/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Grid, Modal, Box, Typography, List, ListItem, ListItemText, Divider, IconButton, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

const UserDetailsModal = ({ isOpen, onClose, user }) => {
    const [rosters, setRosters] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);

    useEffect(() => {
        const fetchFiles = async () => {
            try {
                const response = await fetch(`/templates/GetAllTemplateFiles`);
                if (response.ok) {
                    const files = await response.json();
                    setUploadedFiles(files);
                } else {
                    console.error('Failed to fetch files.');
                }
            } catch (error) {
                console.error('Error during file fetch:', error);
            }
        };
    
        const fetchRosters = async () => {
            try {
                const response = await fetch(`/roster/GetRosters?currentUserId=${user.userId.toString()}`);
                if (response.ok) {
                    const fetchedRosters = await response.json();
                    setRosters(fetchedRosters);
                } else {
                    console.error('Failed to fetch rosters.');
                }
            } catch (error) {
                console.error('Error during roster fetch:', error);
            }
        };
        
        if (isOpen && user && user.userId) {
            fetchRosters();
            fetchFiles();
        }
    }, [isOpen, user]);

    // Function to check if a date is within the last 15 days
    const isWithinLast15Days = (dateString) => {
        const date = new Date(dateString); // Assuming dateString is in a parsable format like 'YYYY-MM-DD'
        const now = new Date();
        const fifteenDaysAgo = new Date(now.setDate(now.getDate() - 15));

        return date >= fifteenDaysAgo;
    };

    const filteredRosters = rosters.filter((roster) => isWithinLast15Days(roster.formattedDate));

    if (!user) {
        return null;
    }

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            aria-labelledby="modal-to-display-user-details"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '90%',
                maxWidth: 600,
                border: '2px solid #e0e0e0',
                bgcolor: 'background.paper',
                boxShadow: 24,
                borderRadius: 3,
                p: 3,
            }}>
                <Box sx={{ position: 'relative', mb: 2 }}>
                    <Typography variant="h6" gutterBottom sx={{ textAlign: 'center', paddingTop: 4, fontWeight: 'bold' }}>
                        Guard Details
                    </Typography>
                    <IconButton
                        onClick={onClose}
                        color="inherit"
                        sx={{ position: 'absolute', top: 0, right: 0, width: 5, height: 5, '&:hover': { backgroundColor: 'transparent' } }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Grid container spacing={3}>
                    {/* Username Section */}
                    <Grid item xs={5}>
                        <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>
                            {user.username}
                        </Typography>
                        {
                            user?.phoneNo &&
                            <>
                                <Typography variant="h8" gutterBottom>
                                    Phone No: {user.phoneNo}
                                </Typography>
                                <br />
                            </>
                        }
                        {
                            user?.email &&
                            <>
                                <Typography variant="h8" gutterBottom style={{maxWidth: '100%', wordWrap: 'break-word'}}>
                                    Email: {user.email}
                                </Typography>
                                <br />
                            </>
                        }
                        {
                            user?.licenseNo &&
                            <>
                                <Typography variant="h8" gutterBottom>
                                    License No: {user.licenseNo}
                                </Typography>
                                <br />
                            </>
                        }
                        {
                            user?.licenseExpiry &&
                            <>
                                <Typography variant="h8" gutterBottom>
                                    License Expiry: {user.licenseExpiry}
                                </Typography>
                                <br />
                            </>
                        }
                        {
                            user?.rate &&
                            <>
                                <Typography variant="h8" gutterBottom>
                                    Rate: {user.rate}
                                </Typography>
                                <br />
                            </>
                        }
                        {
                            user?.bsb &&
                            <>
                                <Typography variant="h8" gutterBottom>
                                    BSB: {user.bsb}
                                </Typography>
                                <br />
                            </>
                        }
                        {
                            user?.account &&
                            <Typography variant="h8" gutterBottom>
                                Account No: {user.account}
                            </Typography>
                        }
                    </Grid>

                    {/* Vertical Divider */}
                    <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Divider orientation="vertical" sx={{ width: 2, backgroundColor: 'black', height: '100%' }} />
                    </Grid>

                    {/* Files Section */}
                    <Grid item xs={5}>
                        <Typography variant="h8" gutterBottom>
                            Compliance Documents
                        </Typography>
                        <List>
                            {uploadedFiles && uploadedFiles.length > 0 ? (
                                uploadedFiles.map((file, index) => (
                                    <ListItem key={index}>
                                        <ListItemText secondary={file.fileName.split('.').slice(0, -1).join('.')} />
                                    </ListItem>
                                ))
                            ) : (
                                <ListItem>
                                    <ListItemText primary="No files uploaded" />
                                </ListItem>
                            )}
                        </List>
                    </Grid>
                </Grid>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Typography variant="h6" gutterBottom>
                    Rosters:
                </Typography>
                <TableContainer component={Paper} sx={{ maxHeight: 200, overflowY: 'auto' }}>
                    <Table aria-label="roster table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 'bold' }}>Site</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Day</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Date</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Time</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredRosters.length > 0 ? (
                                filteredRosters.map((roster, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{roster.site}</TableCell>
                                        <TableCell>{roster.dayName}</TableCell>
                                        <TableCell>{roster.formattedDate}</TableCell>
                                        <TableCell>{roster.time}</TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={4}>
                                        <Typography variant="body1" align="center">
                                            No shifts in the last 15 days
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Modal>
    );
};

export default UserDetailsModal;